/// <reference path="../index.d.ts"/>

module Frontend {

    interface IProductImageScope extends ng.IScope {
        current: number
    }

    function ProductImage($window): ng.IDirective {
        return {
            restrict: 'A',
            controller: productImagesController,
            controllerAs: 'Image'
        }
    }

    class productImagesController {

        static $inject = ['$scope', '$element', '$window'];

        current = 0;
        private images;
        private zoomElement;
        private zoom;

        constructor(private $scope: ng.IScope, private $element: ng.IAugmentedJQuery, private $window) {
            this.images = $element.find('img');

            this.recenterImages();
            this.defineZoomTrigger();

            angular.element($window).bind('resize', () => {
                this.recenterImages();
            });
        }

        defineZoomTrigger() {
            let divs = this.$element.find('div');
            for (let i = 0; i < divs.length; i++) {
                if (angular.element(divs[i]).hasClass('image-zoom')) {
                    this.zoomElement = divs[i];
                    break;
                }
            }
            if (this.zoomElement) {
                if (!this.zoom) {
                    this.zoom = new Drift(this.zoomElement, {
                        inlinePane: true,
                        zoomFactor: 2
                    });
                }
                let currentImage = this.images[this.current];
                let zoomImage = angular.element(currentImage).attr('data-zoom');

                angular.element(this.zoomElement).css({
                    'width': currentImage.offsetWidth + 'px',
                    'margin-left': '-' + ( currentImage.offsetWidth / 2 ) + 'px',
                    'height': currentImage.offsetHeight + 'px',
                    'top': '50%',
                    'margin-top': '-' + ( currentImage.offsetHeight / 2 ) + 'px'
                });

                angular.element(this.zoomElement).attr('data-zoom', zoomImage);
                this.zoom.setZoomImageURL(zoomImage);
            }
        }

        recenterImages() {
            this.$element.css({
                'width': this.$element.parent()[0].offsetWidth + 'px',
                'height': this.$window.innerHeight + 'px'
            });

            for (let i = 0; i < this.images.length; i++) {
                let image = angular.element(this.images[i]);
                this.positionImage(image);
                if (!image[0].offsetWidth) {
                    image.on('load', () => {
                        this.positionImage(image);
                    });
                }

            }
        }

        positionImage(image) {
            let maxWidth = this.$element[0].offsetWidth;

            let height = image[0].offsetHeight;
            let width = image[0].offsetWidth;
            if (height > width && maxWidth >= width) {
                image.css({
                    'margin-left': '-' + ( width / 2 ) + 'px'
                });

            } else {
                let newHeight = maxWidth / width * height;
                image.css({
                    'width': maxWidth + 'px',
                    'margin-left': '-' + ( maxWidth / 2 ) + 'px',
                    'height': 'auto',
                    'top': '50%',
                    'margin-top': '-' + ( newHeight / 2 ) + 'px'
                });
            }
        }

        next() {
            if (this.current < this.images.length - 1) {
                angular.element(this.images[this.current]).removeClass('current');
                this.current = this.current + 1;
                angular.element(this.images[this.current]).addClass('current');
                this.defineZoomTrigger();
            }
        }

        prev() {
            if (this.current > 0) {
                angular.element(this.images[this.current]).removeClass('current');
                this.current = this.current - 1;
                angular.element(this.images[this.current]).addClass('current');
                this.defineZoomTrigger();
            }
        }

        getCurrent() {
            return this.current + 1;
        }

        isLast() {
            return this.current === ( this.images.length - 1 );
        }

        isFirst() {
            return this.current === 0;
        }

    }

    ProductImage.$inject = ['$window'];

    angular.module(window.appConfig.module).directive('productImage', ProductImage);

}