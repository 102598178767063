/// <reference path="../../index.d.ts"/>

module Frontend {

    let template = `
        <div style="position: relative;">
            <select 
                ng-model="value" 
                name="{{ name }}" 
                class="form-control" 
                style="position: absolute; opacity: 0;z-index:1;" 
                ng-options="item as item.title for item in options track by item.id"></select>
            <div class="selected form-control">
                {{ label }} <span ng-bind-html="selected"></span>
            </div>
        </div>
    `;

    interface ISelectAttributes extends ng.IAttributes {
        label: string
        options: string
        default: string
        currency: string
        value: string
        priceField: string
    }

    interface ISelectScope extends ng.IScope {
        label: string
        options: [any]
        value: any
        model: number|string
        selected: string
    }

    function Select($sce, $filter): ng.IDirective {
        return {
            restrict: 'E',
            template: template,
            scope: {
                model: '=',
                name: '@'
            },
            link: function ($scope: ISelectScope, $element: ng.IAugmentedJQuery, $attrs: ISelectAttributes) {
                $scope.label = $attrs.label;
                $scope.options = angular.fromJson($attrs.options);
                if (angular.isDefined($attrs.value) && $attrs.value.length) {
                    $scope.value = $filter('filter')($scope.options, {id: $attrs.value})[0];
                } else {
                    $scope.selected = $sce.trustAsHtml($attrs.default);
                }
                let priceField = angular.isDefined($attrs.priceField) ? $attrs.priceField : 'price';

                $scope.$watch('value', (newValue:any)=> {
                    if (newValue && newValue.id) {
                        $scope.model = newValue.id;
                        $scope.selected = newValue.title;
                        if (numeral(newValue[priceField]).value() > 0) {
                            let price = $filter('currency')(numeral(newValue[priceField]).format('0.00'), $attrs.currency + ' ');
                            $scope.selected += '<span class="price">' + price + '</span>';
                        }
                        $scope.selected = $sce.trustAsHtml($scope.selected);
                    }
                });
            }
        }
    }

    Select.$inject = ['$sce', '$filter'];

    angular.module(window.appConfig.module).directive('wdSelect', Select);

}