/// <reference path="../index.d.ts"/>

module Frontend {

    interface IAutoExpandAttributes extends ng.IAttributes {
        dropDown:string
    }

    function DropDown($document):ng.IDirective {
        return {
            restrict: 'A',
            link: function ($scope:ng.IScope, $element:ng.IAugmentedJQuery, $attrs:IAutoExpandAttributes) {
                $element.bind('click', ()=> {
                    if( $element.hasClass('open') ) {
                        $element.removeClass('open');
                    } else {
                        $element.addClass('open');
                    }
                });
                $document.bind('click', function(event){
                    let found = false;
                    angular.forEach(event.path,(value)=>{
                        if( value === $element[0] ) {
                            found = true;
                            return;
                        }
                    });
                    if( !found ) {
                        $element.removeClass('open');
                    }
                });

            }
        }
    }
    DropDown.$inject = ['$document'];


    angular.module(window.appConfig.module).directive('dropDown', DropDown);

}