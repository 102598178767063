/// <reference path="../index.d.ts"/>

module Frontend {

    interface ISidebarAttributes extends ng.IAttributes {

    }

    function Sidebar($window):ng.IDirective {
        return {
            restrict: 'A',
            link: function ($scope:ng.IScope, $element:ng.IAugmentedJQuery, $attrs:ISidebarAttributes) {
                let sidebarHeight = $element[0].offsetHeight;
                let windowHeight = $window.innerHeight;
                angular.element(angular.element(document).find('body')).css({'min-height': sidebarHeight + 'px'});
                angular.element($window).bind('scroll', (e)=> {
                    if (windowHeight + $window.pageYOffset < sidebarHeight)
                        $element.css({
                            top: '-' + $window.pageYOffset + 'px'
                        });
                });
            }
        }
    }

    Sidebar.$inject = ['$window'];


    angular.module(window.appConfig.module).directive('sidebar', Sidebar);

}