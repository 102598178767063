/// <reference path="../index.d.ts"/>

interface IMoneyFilter {
    (input:string, currency:string, format:string):string;
}

module Frontend {

    function MoneyFilter() {
        return function (input, currency, format) {
            format = angular.isDefined(format) ? format : '0.00';
            console.log(currency);
            currency = angular.isDefined( currency ) && currency.length ? currency : '€';
            return numeral(input).format(format) + ' ' + currency;
        };
    }

    angular.module(window.appConfig.module).filter('money', MoneyFilter);

}