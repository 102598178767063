/// <reference path="../index.d.ts"/>

module Frontend {

    interface IToggleClassAttributes extends ng.IAttributes {
        toggleClass:string
        className:string
    }

    function ToggleClass():ng.IDirective {
        return {
            restrict: 'A',
            link: function ($scope:ng.IScope, $element:ng.IAugmentedJQuery, $attrs:IToggleClassAttributes) {
                let className = $attrs.className ? $attrs.className : 'active';
                $element.bind('click', (e)=> {
                    let target = document.getElementById($attrs.toggleClass);
                    if (angular.element(target).hasClass(className)) {
                        $element.removeClass('added');
                        angular.element(target).removeClass(className);
                    } else {
                        $element.addClass('added');
                        angular.element(target).addClass(className);
                    }
                });
            }
        }
    }


    angular.module(window.appConfig.module).directive('toggleClass', ToggleClass);

}