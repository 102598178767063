/// <reference path="../index.d.ts"/>

module Frontend {

    interface IScope extends ng.IScope {
        rate
        rateIs()
    }

    function Rating(): ng.IDirective {
        return {
            restrict: 'A',
            controller: ratingController,
            controllerAs: 'Rating'
        }
    }

    class ratingController {

        static $inject = ['$scope', '$element'];

        private rating: number;

        constructor(private $scope: ng.IScope, private $element: ng.IAugmentedJQuery) {
            $scope.$watch(()=> {
                return this.rating
            }, (value) => {
                $element.find('input').val( value );
            });
        }

        set(rate: number) {
            this.rating = rate;
        }

        is(rate: number) {
            return this.rating == rate;
        }
    }

    angular.module(window.appConfig.module).directive('rating', Rating);

}