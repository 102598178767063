/// <reference path="../index.d.ts"/>

module Backend {

    export class InvoiceService {

        title;
        seller = {
            title: '',
            attributes: []
        };
        client = {
            title: '',
            attributes: []
        };

        setTitle(title) {
            this.title = title;
        }

        setClient(data) {
            this.client = data;
        }

        setSeller(data) {
            this.seller = data;
        }

        getAll() {
            return {
                info: {
                    title: 'awesome Document',
                    author: 'Tik saskaita',
                },
                content: [
                    {
                        text: 'Sąskaita faktūra',
                        style: 'title'
                    },
                    {
                        alignment: 'justify',
                        columns: [
                            {
                                table: {
                                    body: this.getSeller(),
                                },
                                layout: 'noBorders'
                            },
                            {
                                width: 50,
                                text: ''
                            },
                            {
                                table: {
                                    body: this.getClient(),
                                },
                                layout: 'noBorders'
                            },
                        ]
                    },
                ],
                styles: {
                    title: {
                        fontSize: 18,
                        alignment: 'center',
                        margin: [0,0,0,20]
                    },
                    subtitle: {
                        fontSize: 12,
                        bold: true,
                        margin: [0, 0, 0, 5]
                    },
                    infoTitle: {
                        fontSize: 10,
                        color: '#777777'
                    },
                    infoContent: {
                        fontSize: 10
                    }
                }
            };
        }

        getClient() {
            let obj = this.makeAttributes( this.client.attributes );
            obj.unshift(
                [{
                    text: this.client.title,
                    style: 'subtitle'
                }]
            );
            return obj;
        }

        getSeller() {
            let obj = this.makeAttributes( this.seller.attributes );
            obj.unshift(
                [{
                    text: this.seller.title,
                    style: 'subtitle'
                }]
            );
            return obj;
        }

        makeAttributes(attrs) {
            let obj = [];
            for (let i = 0; i < attrs.length; i++) {
                if (attrs[i].hideTitle !== 1 ) {
                    obj.push([{
                        table: {
                            body: [
                                [
                                    {
                                        text: attrs[i].field,
                                        style: 'infoTitle'
                                    },
                                    {
                                        text: attrs[i].content,
                                        style: 'infoContent'
                                    }
                                ]
                            ]
                        },
                        layout: 'noBorders'
                    }]);
                } else {
                    obj.push([{
                        text: attrs[i].content,
                        style: 'infoContent'
                    }]);
                }
            }
            return obj;
        }

    }

    angular.module(window.appConfig.module).service('InvoiceService', InvoiceService);
}