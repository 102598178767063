/// <reference path="../index.d.ts"/>

module Frontend {

    class AppController {

        static $inject = ['$scope', '$http', '$window'];
        item: IItem;
        cart: ICart;
        cartCount = 0;
        countries = [];
        errors = {};

        constructor(private $scope: ng.IScope, private $http: ng.IHttpService, private $window) {
            this.cart = {
                items: [],
                total: 0
            };
            this.item = {
                id: 0,
                quantity: 1,
                attributes: {}
            };
            $scope.$on('cart-update', function (event, data) {
                this.cart = data;
            }.bind(this));

            $scope.$watch(() => {
                return this.cart;
            }, () => {
                this.countCart();
            }, true);

            $scope.$on('errors', function (event, errors) {
                this.errors = errors;
            }.bind(this));
        }

        toCart(id) {
            this.item.id = id;
            this.clearErrors();
            this.$http.post(window.appConfig.cartAddUrl, this.item).then((response: IResponse) => {
                this.cart = response.data.data.cart;
                swal({
                    title: "Success!",
                    text: response.data.message,
                    type: "success",
                    showCancelButton: true,
                    confirmButtonColor: "#32bcbc",
                    confirmButtonText: "Go to cart",
                    cancelButtonText: "Continue shopping",
                    closeOnConfirm: false
                }, function () {
                    this.$window.location.href = window.appConfig.cartUrl;
                }.bind(this));
            }, (response: IResponse) => {
                swal({
                    title: "Error!",
                    text: response.data.message,
                    type: "error",
                    confirmButtonColor: "#32bcbc",
                    confirmButtonText: "Ok"
                });
            });
        }

        countCart() {
            this.cartCount = 0;
            angular.forEach(this.cart.items, () => {
                this.cartCount++;
            });
        }

        searchCountry(search) {
            var url = '/api/countries/' + search;
            return this.$http.get(url)
                .then(function (response) {
                    this.countries = response.data;
                }.bind(this));
        }

        hasError(field) {
            if (Object.keys(!this.errors).length) {
                return false;
            }
            if (angular.isUndefined(this.errors[field])) {
                return false;
            }

            return true;
        }

        getError(field) {
            return this.errors[field][0];
        }

        clearErrors() {
            this.errors = {};
        }

        inc() {
            this.item.quantity = this.item.quantity * 1 + 1;
        }

        dec() {
            this.item.quantity = this.item.quantity > 1 ? this.item.quantity * 1 - 1 : 1;
        }

        config() {
            return window.appConfig;
        }

    }

    angular.module(window.appConfig.module).controller('AppController', AppController);

}