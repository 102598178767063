/// <reference path="../index.d.ts"/>

module Frontend {

    interface IConfirmScope extends ng.IScope {
        onWheelUp();
        onWheelDown();
    }

    function OnWheel(): ng.IDirective {
        return {
            restrict: 'A',
            scope: {
                onWheelUp: '&?',
                onWheelDown: '&?'
            },
            link: function ($scope: IConfirmScope, $element: ng.IAugmentedJQuery) {
                $element.bind('mousewheel', (e: WheelEvent|JQueryEventObject|any) => {
                    e.preventDefault();
                    if (e.deltaY < 0) {
                        $scope.$apply(() => {
                            $scope.onWheelUp();
                        });
                    } else {
                        $scope.$apply(() => {
                            $scope.onWheelDown();
                        });
                    }
                });
            }
        }
    }

    OnWheel.$inject = [];

    angular.module(window.appConfig.module).directive('onWheel', OnWheel);

}
