/// <reference path="../index.d.ts"/>

module Frontend {

    function Directive($http: ng.IHttpService): ng.IDirective {
        return {
            restrict: 'A',
            controller: Controller,
            controllerAs: 'Payment'
        }
    }

    Directive.$inject = ['$http'];

    interface IPaymentData {
        holder?: string;
        pan?: string;
        exp_year?: string;
        exp_month?: string;
        cvc?: string;
    }

    class Controller {

        static $inject = ['$scope', '$element', '$http'];

        errorMessage: string;
        data: IPaymentData = {};
        errors = {};

        constructor(private $scope: ng.IScope, private $element: ng.IAugmentedJQuery, private $http: ng.IHttpService) {
            $scope.$on('errors', function (event, errors) {
                this.errors = errors;
            }.bind(this));
        }

        pay() {
            this.errors = {}; // clear errors
            this.errorMessage = ''; // clear error message
            this.$http.post(window.appConfig.paymentUrl, {
                payment: this.data,
                order: this.$scope.ngDialogData.order
            }).then((response: IResponse) => {

            }, (response: IResponse) => {
                this.errorMessage = response.data.message;
            });
        }

        hasError(field) {
            if (Object.keys(!this.errors).length) {
                return false;
            }
            if (angular.isUndefined(this.errors[field])) {
                return false;
            }

            return true;
        }

        getError(field) {
            return this.errors[field][0];
        }
    }

    angular.module(window.appConfig.module).directive('payment', Directive);

}