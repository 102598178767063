/// <reference path="index.d.ts" />

declare var Drift: any;

module Frontend {

    angular.module(window.appConfig.module, [
        'ngSanitize',
        'ngDialog',
        'ui.select'
    ]).constant('AppConfig', {}).config(['$compileProvider', '$httpProvider', '$interpolateProvider', ($compileProvider: ng.ICompileProvider, $httpProvider: ng.IHttpProvider, $interpolateProvider: ng.IInterpolateProvider) => {
        // Speed up when not in production mode
        if (!window.appConfig.debug) {
            $compileProvider.debugInfoEnabled(false);
        }

        // Change angular brackets to avoid mixing with laravel
        $interpolateProvider.startSymbol('${').endSymbol('}!');

        // Http settings
        $httpProvider.useApplyAsync(true);
        $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
        $httpProvider.defaults.headers.common['Accept'] = 'application/json';

        $httpProvider.interceptors.push(['$rootScope', '$q', '$window', ($rootScope, $q, $window) => {
            $rootScope.$broadcast('errors', []);

            let responseSuccess = (response: IResponse) => {
                if (!angular.isObject(response.data)) {
                    return response;
                }
                if (response.data && response.data.redirect) {
                    $window.location.href = response.data.redirect;
                }
                return response;
            };

            let responseError = (response: IResponse) => {
                if (!angular.isObject(response.data)) {
                    return $q.reject(response);
                }
                if (response.data && response.data.redirect) {
                    $window.location.href = response.data.redirect;
                }
                if (response.data && response.data.errors) {
                    $rootScope.$broadcast('errors', response.data.errors);
                }
                return $q.reject(response);
            };

            return {
                response: responseSuccess,
                responseError: responseError
            }
        }]);

    }]).run(() => {

    });
}