/// <reference path="../index.d.ts"/>

module Frontend {

    interface IConfirmScope extends ng.IScope {
        onConfirm()
    }

    interface IConfirmAttributes extends ng.IAttributes {
        confirm: string
    }


    function Confirm(): ng.IDirective {
        return {
            restrict: 'A',
            scope: {
                onConfirm: '&?'
            },
            link: function ($scope: IConfirmScope, $element: ng.IAugmentedJQuery, $attrs: IConfirmAttributes) {
                console.log('test');
                $element.bind('click', (e) => {
                    console.log('test');
                    e.preventDefault();
                    swal({
                        title: $attrs.confirm,
                        type: 'warning',
                        showCancelButton: true,
                        showConfirmButton: true,
                        confirmButtonColor: "#32bcbc",
                        confirmButtonText: 'Confirm',
                        closeOnConfirm: true,
                    }, function () {
                        $scope.$apply(()=> {
                            $scope.onConfirm();
                        });
                    });
                });
            }
        }
    }

    Confirm.$inject = [];

    angular.module(window.appConfig.module).directive('confirm', Confirm);

}
