/// <reference path="../index.d.ts"/>

module Frontend {

    interface ICartScope extends ng.IScope {
        current: number
    }

    function Cart($http: ng.IHttpService, $window: ng.IWindowService): ng.IDirective {
        return {
            restrict: 'A',
            controller: cartController,
            controllerAs: 'Cart'
        }
    }

    Cart.$inject = ['$http', '$window'];

    class cartController {

        static $inject = ['$scope', '$element', '$http', '$window'];

        constructor(private $scope: ICartScope, private $element: ng.IAugmentedJQuery, private $http: ng.IHttpService, private $window: ng.IWindowService) {

        }

        removeItem(key) {
            this.$http.post(window.appConfig.cartRemoveUrl, {key: key}).then((response: IResponse) => {
                angular.element(this.$element[0].getElementsByClassName('item-' + key)).remove();
                this.$scope.$broadcast('cart-update', response.data);
                if (response.data.items.length === 0) {
                    this.$window.location.reload();
                }
            });
        }
    }

    angular.module(window.appConfig.module).directive('cart', Cart);

}