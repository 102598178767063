/// <reference path="../index.d.ts"/>

module Frontend {

    interface IAutoExpandAttributes extends ng.IAttributes {
        autoExpand: string
    }

    function AutoExpand(): ng.IDirective {
        return {
            restrict: 'A',
            link: function ($scope: ng.IScope, $element: ng.IAugmentedJQuery, $attrs: IAutoExpandAttributes) {
                $element.bind('keyup', (e) => {
                    $element.css('height', ( angular.isDefined($attrs.autoExpand) ? $attrs.autoExpand : 32 ) + 'px'); // reset height
                    $element.css('height', ( $element[0].scrollHeight + 2 ) + 'px');
                });
            }
        }
    }

    angular.module(window.appConfig.module).directive('autoExpand', AutoExpand);

}