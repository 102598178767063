/// <reference path="../index.d.ts"/>

module Frontend {

    function Directive($http: ng.IHttpService, ngDialog): ng.IDirective {
        return {
            restrict: 'A',
            controller: checkoutController,
            controllerAs: 'Checkout'
        }
    }

    Directive.$inject = ['$http', 'ngDialog'];

    interface ICountryModel {
        vat: number;
        delivery: number;
        name: string;
        id: number;
    }

    interface ICheckoutData {
        country_id?: number;
    }

    class checkoutController {

        static $inject = ['$scope', '$element', '$http', 'ngDialog'];

        country: ICountryModel;
        countryJson: string;
        data: ICheckoutData = {};

        constructor(private $scope: ng.IScope, private $element: ng.IAugmentedJQuery, private $http: ng.IHttpService, private ngDialog) {
            $scope.$watch(() => {
                return this.countryJson;
            }, (value) => {
                delete this.data.country_id;
                if (angular.isDefined(value)) {
                    this.country = angular.fromJson(value);
                    this.data.country_id = this.country.id;
                }
            });
        }

        delivery() {
            if (angular.isUndefined(this.country) || !this.country.delivery) {
                return 0;
            }
            return numeral(this.country.delivery).value();
        }

        vat(total) {
            if (angular.isUndefined(this.country) || !this.country.vat) {
                return 0;
            }
            return numeral(this.country.vat / 100 * (total + this.delivery())).value();
        }

        total(total) {
            if (angular.isUndefined(this.country) || !this.country.vat) {
                return total;
            }
            total = numeral(total).value() + this.delivery();
            return this.country.vat / 100 * total + total;
        }

        pay() {
            this.$http.post(window.appConfig.orderValidateUrl, this.data).then((response: IResponse) => {
                if (response && !response.errors) {
                    this.ngDialog.open({
                        templateUrl: window.appConfig.paymentTemplateUrl,
                        className: 'ngdialog-theme-default payment',
                        data: {
                            order: this.data
                        },
                        closeByDocument: false,
                        controller: ['$scope', '$element', ($scope, $element) => {
                            // controller stuff
                        }],
                        preCloseCallback: function () {
                            return true;
                        }.bind(this)
                    });
                }
            });
        }
    }

    angular.module(window.appConfig.module).directive('checkout', Directive);

}