/// <reference path="../index.d.ts"/>

module Backend {

    export class PDFService {

        text( text, style ) {
            let obj = { text : text };
            if( angular.isObject( style ) ) {
                obj = angular.merge( obj, style );
            } else {
                obj['style'] = style;
            }
            return obj;
        }

        table( body, layout ) {
            let obj = {
                table : {
                    body : body
                }
            };
            if( angular.isDefined( layout ) ) {
                obj['layout'] = layout;
            }
            return obj;
        }
    }

    angular.module(window.appConfig.module).service('PDFService', PDFService);
}